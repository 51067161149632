<template>
  <div id="mian">
    <div>
      <div class="content">
        <div class="desc">
        <h5 class="jsxx">退款信息</h5>
        <div>
          <el-button v-if="false" class="reEmailBt" type="primary">重发邮件</el-button>
        </div>
      </div>
        <ul class="listBox">
          <li><span>退款订单号</span><i class="hidden1">{{detail.mchRefundNo}}</i></li>
          <li><span>退款流水号</span><i class="hidden1">{{detail.plfRefundNo}}</i></li>
          <li><span>三方退款订单号</span><i class="hidden1">{{detail.upsRefundNo}}</i></li>
          <li><span>三方退款流水号</span><i>{{detail.thdRefundNo}}</i></li>
          <li><span>商户名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.mchName" placement="top">
              <b>{{detail.mchName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>商户编号</span><i class="hidden1">{{detail.mchId}}</i></li>
          <li><span>店铺名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.storeName" placement="top">
              <b>{{detail.storeName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>店铺编号</span><i class="hidden1">{{detail.storeId}}</i></li>
          <li><span>报备商户</span><i class="hidden1">{{detail.tradeMchName}}</i></li>
          <li><span>报备商户号</span><i class="hidden1">{{detail.tradeMchNo}}</i></li>
          <li><span>代理商名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.agentName" placement="top">
              <b>{{detail.agentName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>服务商名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.servicerName" placement="top">
              <b>{{detail.servicerName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>所属机构</span><i class="hidden1">{{detail.orgName?detail.orgName:''}}</i></li>
          <li><span>渠道商户号</span><i class="hidden1">{{detail.channelMchNo?detail.channelMchNo:''}}</i></li>
          <li><span>创建时间</span><i class="hidden1">{{detail.createTime}}</i></li>
          <li><span>退款时间</span><i class="hidden1">{{detail.refundTime}}</i></li>
          <li><span>产品类型</span><i class="hidden1">{{detail.productName}}</i></li>
          <li><span>产品通道</span><i class="hidden1">{{detail.payChannelName}}</i></li>
          <li><span>交易金额</span><i class="hidden1">{{detail.orderAmount/100 | formatMoney}}</i></li>
          <li><span>退款金额</span><i class="hidden1">{{detail.refundAmount/100 | formatMoney}}</i></li>
          <li><span>通知地址</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.notifyUrl" placement="top">
              <b>{{detail.notifyUrl}}</b>
            </el-tooltip>
          </i></li>
          <li><span>退款状态</span>
            <i v-if="detail.refundStatus == 'WAIT'">退款中</i>
            <i v-if="detail.refundStatus == 'REJECT'">已驳回</i>
            <i v-if="detail.refundStatus == 'SUCCESS'">退款成功</i>
            <i v-if="detail.refundStatus == 'FAIL'">退款失败</i>
            <i v-if="detail.refundStatus == 'PROCESSING'">待处理</i>
            <i v-if="detail.refundStatus == 'PASS'">审核通过</i>
          </li>
          <li><span>响应码</span><i class="hidden1">{{detail.resultCode}}</i></li>
          <li><span>响应码描述</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.resultMessage" placement="top">
              <b>{{detail.resultMessage}}</b>
            </el-tooltip>
          </i></li>
          <li><span>异常码</span><i class="hidden1">{{detail.errorCode}}</i></li>
          <li><span>异常描述</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.errorMessage" placement="top">
              <b>{{detail.errorMessage}}</b>
            </el-tooltip>
          </i></li>
        </ul>
      </div>
      <div class="desc">
        <h5 class="jsxx">退款通知信息</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>通知时间</p>
            </td>
            <td>
              <p>请求类型</p>
            </td>
            <td>
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
						<td>
						  <p>状态码</p>
						</td>
						<td>
						  <p>返回信息</p>
						</td>
          </tr>
          <tr v-for="(item,index) in notices" :key="index">
            <td>
              <p>{{item.notifyTime}}</p>
            </td>
            <td>
              <p v-if="item.notifyType == 'PLATFORM'">平台</p>
              <p v-if="item.notifyType == 'THIRD'">第三方</p>
            </td>
            <td>
              <p v-if="item.reachStatus == true">已到达</p>
              <p v-if="item.reachStatus == false || item.reachStatus == null">未响应</p>
            </td>
            <td>
              <p v-if="item.notifyStatus == 'SUCCESS'">成功</p>
               <p v-if="item.notifyStatus == 'FAIL'">失败</p>
            </td>
						<td>
						  <p>{{item.resultCode}}</p>
						</td>
						<td>
						  <p>{{item.resultMessage}}</p>
						</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
 import {
    refundDetail
	} from '@/api/operation/inform.js'
  export default {
    data() {
      return {
        crumbs: "交易详情",
        notices:[],
        detail:{}
      }
    },
    created(){
      this.getLists();
    },
    activated(){
      this.getLists();
    },
    methods: {
      //菜单切换
      navClick(data) {
        this.crumbs = data
      },
      // 获取列表
      getLists(){
        refundDetail(this.$route.query.refundId).then(res=>{
          let resOther = res.resultData.detail
          
          for(let item in resOther){
            if(resOther[item] == null||resOther[item] == ''){
              resOther[item] = ''
            }
          }
          console.log(resOther)
          this.detail = resOther
          this.notices = res.resultData.notices
        }).catch()

      }
    }
  }
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab1{
  min-height: auto !important;
}
</style>
